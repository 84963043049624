<script setup lang="ts">
import { RouterView, useRouter } from "vue-router";
import { onErrorCaptured, onMounted, provide, ref, watch } from "vue";
import { useCsrfStore } from "@/stores/csrf";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useNotification } from "@kyvg/vue3-notification";
import Swal from "sweetalert2";
//import LanguagePicker from "@/components/LanguagePicker.vue";
import { createPaymentReturnHandler } from "@/payment/PaymentReturnHandler";
import { useRecaptcha } from "@/recaptcha/Recaptcha";
import { tracker } from "@/analytics/Tracker";
import { errorHandler } from "@/errors/ErrorHandler";

const userStore = useUserStore();
useRecaptcha();
userStore.validToken();
const { isLoggedIn, user, isAdmission } = storeToRefs(userStore);
const { t, locale } = useI18n();
const router = useRouter();
const notification = useNotification();

errorHandler.setStrings({
    unauthorized: t("labels.unauthorizedError"),
    generic: t("labels.genericError"),
})
errorHandler.setRouter(router);

const pageTitle = ref('UNYP.cz');
function setPageTitle(value: string) {
    pageTitle.value = value;
}
provide('pageTitle', { pageTitle, setPageTitle });
watch(
    pageTitle,
    (title: string): void => {
        document.title = title;
    },
    { immediate: true }
);

onMounted(() => {
    const app = document.getElementById("app");
    if (app) {
        const csrf = app.dataset.csrf;
        const store = useCsrfStore();
        store.setToken(csrf as string);
    }
    if (localStorage.lang) {
        locale.value = localStorage.lang;
    }
    createPaymentReturnHandler().registerHandler();
});

onErrorCaptured((err) => {
    errorHandler.handle(err);
});

watch(
    locale,
    () => localStorage.lang = locale.value
);

let isLoggingOut = false;
async function logout(): Promise<void> {
    if (isLoggingOut) {
        return;
    }
    isLoggingOut = true;
    try {
        await userStore.logout();
        tracker.trackEvent("logout", {});
    } catch (e) {
        window.Sentry?.captureException(e);
        await Swal.fire("user.logoutError", "", "error");
        isLoggingOut = false;
        return;
    }
    isLoggingOut = false;
    notification.notify({
        title: t("user.logoutSuccess"),
        type: "success",
    });
    await router.push({name: "home"});
}
</script>

<template>
    <div id="upper-content">
        <notifications position="top center"/>
        <div class="bg-light-blue">
            <div class="container">
                <div class="d-flex align-items-start align-items-md-center justify-content-between py-4">
                    <div>
                        <a href="https://www.unyp.cz/">
                            <img
                                src="/images/logo-olympic-horizontal.png"
                                alt="University of New You in Prague"
                                class="img-fluid"
                            />
                        </a>
                    </div>
                    <div class="text-end ms-5 ms-md-0">
                        <!--
                        <div class="mb-4">
                            <LanguagePicker id="language-picker" />
                        </div>
                        -->
                        <div class="mb-2 d-none d-md-block">
                            <a href="https://www.unyp.cz/" class="btn btn-outline-secondary"
                            >{{ $t("menu.goToUnyp") }}</a>
                        </div>
                        <div v-if="isLoggedIn" class="d-none d-md-block">
                            {{ user?.email }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ul id="menu">
            <li v-if="!isLoggedIn">
                <RouterLink to="/"> Home </RouterLink>
            </li>
            <li v-if="!isLoggedIn">
                <RouterLink to="/login"> {{ $t("menu.login") }} </RouterLink>
            </li>
            <li v-if="isLoggedIn">
                <RouterLink to="/account/my-applications"> {{ $t("menu.myApplications") }} </RouterLink>
            </li>
            <li v-if="isLoggedIn">
                <RouterLink :to="{ name: 'application-new' }"> {{ $t("menu.newApplication") }} </RouterLink>
            </li>
            <li>
                <RouterLink to="/online-payment"> {{ $t("menu.payOnline") }} </RouterLink>
            </li>
            <li v-if="isLoggedIn">
                <RouterLink to="/account"> {{ $t("menu.myAccount") }} </RouterLink>
            </li>
            <li v-if="isLoggedIn">
                <a href="#" v-if="isLoggedIn" @click.prevent="logout"> {{ $t("menu.logout") }} </a>
            </li>
            <li>
                <a href="https://www.unyp.cz/contact/" target="_blank">{{ $t("menu.contact") }}</a>
            </li>
            <li v-if="isAdmission">
                <a href="/admin">{{ $t("menu.admin") }}</a>
            </li>
        </ul>

        <RouterView />
    </div>

    <div class="bg-light-blue content-bottom-separator"></div>

    <div class="container text-center py-5">
        <h2 class="section-heading">Follow us</h2>
        <ul class="list-unstyled" id="social-icons">
            <li>
                <a class="social-icon no-ext-icon facebook" href="https://www.facebook.com/UNYP.edu" target="_blank" title="Facebook">Facebook</a>
            </li>
            <li>
                <a class="social-icon no-ext-icon instagram" href="https://www.instagram.com/unyp/" target="_blank" title="Instagram">Instagram</a>
            </li>
            <li>
                <a class="social-icon no-ext-icon linkedin" href="http://www.linkedin.com/company/university-of-new-york-in-prague" target="_blank" title="Linkedin">Linkedin</a>
            </li>
            <li>
                <a class="social-icon no-ext-icon tiktok" href="https://www.tiktok.com/@unyp.edu" target="_blank" title="TikTok">TikTok</a>
            </li>
            <li>
                <a class="social-icon no-ext-icon youtube" href="https://www.youtube.com/user/unypvideos" target="_blank" title="Youtube">Youtube</a>
            </li>
            <li>
                <a class="social-icon no-ext-icon rss" href="https://www.unyp.cz/feed/" target="_blank" title="RSS">RSS</a>
            </li>
        </ul>
    </div>

    <div class="bg-light-blue">
        <div class="container py-5">
            <div class="row">
                <div class="col-lg-4 text-center text-lg-start mb-4">
                    <h2>UNYP Chronicle Newsletter</h2>
                    <div class="row g-1 align-items-center mb-3 justify-content-center justify-content-lg-start newsletter-signup-form">
                        <div class="col-auto">
                            <input type="email" id="mailchimp-email" value="" size="25" maxlength="128" class="form-control required" placeholder="@">
                        </div>
                        <div class="col-auto">
                            <a href="http://unyp.us13.list-manage.com/subscribe?u=29ca77fab63f62108375cb822&amp;id=b47fb8a899" target="_blank" id="mailchimp-submit" class="btn btn-primary no-ext-icon">Subscribe</a>
                        </div>
                    </div>
                    <p>The e-mail address you provide will be used only to send you the newsletter. Your privacy is important to us.</p>
                    <p>For more information download our <a href="https://www.unyp.cz/brochures/">UNYP Brochure</a>.</p>
                    <hr class="d-lg-none mt-4">
                </div>

                <div class="col-lg-4 d-flex align-items-center justify-content-center mb-4">
                    <img src="https://www.unyp.cz/wp-content/themes/unyp/img/layout/logo-olympic-vertical.png" alt="UNYP logo">
                </div>

                <div class="col-lg-4 text-center text-lg-start">
                    <hr class="d-lg-none mb-4">
                    <h2>Contacts</h2>
                    <p>
                        <strong>University of New York in Prague<br> Londýnská 41, 120 00 Praha</strong>
                        <br><br>
                        <strong>ID no:</strong> 25676598
                        <br>
                        <strong>Phone:</strong>
                        <a href="tel:+420224221261">+420 224 221 261</a>
                        &nbsp;
                        <a href="skype:admission.unyp?call">
                            <img src="https://www.unyp.cz/wp-content/themes/unyp/img/layout/skype-icon.png" class="me-1" alt="">Skype
                        </a>
                        <br>
                        <strong>Email: </strong><a href="mailto:unyp@unyp.cz">unyp@unyp.cz</a>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-dark-blue" id="footer-logos">
        <div class="container">
            <div class="row justify-content-between w-100">
                <div class="col-auto"><img src="https://www.unyp.cz/wp-content/themes/unyp/img/layout/footer-logos/1.png" alt="Ministerstvo školství, mládeže a tělovýchovy"></div>
                <div class="col-auto"><img src="https://www.unyp.cz/wp-content/themes/unyp/img/layout/footer-logos/2.png" alt="International Accreditation Council for Business Education"></div>
                <div class="col-auto"><img src="https://www.unyp.cz/wp-content/themes/unyp/img/layout/footer-logos/3.png" alt="British Accreditation Council"></div>
                <div class="col-auto"><img src="https://www.unyp.cz/wp-content/themes/unyp/img/layout/footer-logos/4.png" alt="Česká asociace MBA škol"></div>
                <div class="col-auto"><img src="https://www.unyp.cz/wp-content/themes/unyp/img/layout/footer-logos/5.png" alt="The Association of Chartered Certified Accountants"></div>
                <div class="col-auto last"><img src="https://www.unyp.cz/wp-content/themes/unyp/img/layout/footer-logos/7.png" alt="The Central and East European Management Development Association"></div>
            </div>
        </div>
    </div>


    <div id="footer-bottom">
        <div class="container">
            <div class="row flex-row-reverse">
                <div
                    class="col-12 col-md-5 text-center text-md-end mb-3 mb-md-0"
                >
                    <ul role="navigation">
                        <li>
                            <a
                                href="https://www.unyp.cz/privacy-policy/"
                                class="no-ext-icon"
                                >{{ $t("menu.privacyPolicy") }}</a
                            >
                        </li>
                    </ul>
                </div>
                <div class="col-12 col-md-7 text-center text-md-start">
                    © Copyright,
                    <a href="https://www.unyp.cz/" class="no-ext-icon">University&nbsp;of&nbsp;New&nbsp;York&nbsp;in&nbsp;Prague</a>
                </div>
            </div>
        </div>
    </div>
</template>
