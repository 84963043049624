import { defineStore } from "pinia";
import type { ApplicationInterface } from "@/application/ApplicationInterface";
import { applicationApi } from "@/application/ApplicationApi";
import { ApplicationError } from "@/application/ApplicationError";
import type { LanguageInterface } from "@/application/LanguageInterface";
import type { EducationInterface } from "@/application/EducationInterface";
import { applicationLanguageApi } from "@/application/ApplicationLanguageApi";
import { applicationEducationApi } from "@/application/ApplicationEducationApi";

export const useApplicationStore = defineStore("application", {
    state: () => {
        return {
            application: {} as ApplicationInterface,
            applicationEducations: [] as EducationInterface[],
            applicationLanguages: [] as LanguageInterface[],
            isLoaded: false,
        };
    },
    actions: {
        logout(): void {
            this.setApplication({} as ApplicationInterface);
            this.isLoaded = false;
        },
        setApplication(app: ApplicationInterface): void {
            this.application = app;
            if (!this.application) {
                this.application = {} as ApplicationInterface;
            }

            if (this.application.educations) {
                this.applicationEducations = this.application.educations;
            } else {
                this.applicationEducations = [] as EducationInterface[];
            }
            if (this.applicationLanguages) {
                this.applicationLanguages = this.application.languages;
            } else {
                this.applicationLanguages = [] as LanguageInterface[];
            }
        },
        async fetchOpen(): Promise<ApplicationInterface | null> {
            if (this.isLoaded && !this.application.is_locked) {
                return this.application;
            }

            const app = await applicationApi.findOpen();
            if (!app) {
                this.isLoaded = true;
                return null;
            }
            this.setApplication(app);
            this.isLoaded = true;
            return this.application;
        },
        async saveProgram(): Promise<ApplicationInterface> {
            if (!this.application) {
                throw new ApplicationError("There is no application to save");
            }
            this.application = await applicationApi.saveProgramSelection(
                this.application
            );

            return this.application;
        },
        async ensureApplication(appId: number): Promise<ApplicationInterface> {
            const app = await applicationApi.findById(appId);
            if (
                this.isLoaded &&
                this.application &&
                this.application.id === appId &&
                this.application.version === app?.version
            ) {
                return this.application;
            }

            this.isLoaded = false;
            if (!app) {
                throw new ApplicationError(
                    "Requested application doesn't exist"
                );
            }
            this.setApplication(app);
            this.isLoaded = true;
            return this.application;
        },
        async savePersonalInfo(
            application: ApplicationInterface,
            languages: LanguageInterface[],
            educations: EducationInterface[]
        ) {
            const response: ApplicationInterface =
                await applicationApi.savePersonal(application);
            const keepLanguages = [] as number[];
            const newLanguages = [] as LanguageInterface[];
            let counter = 0;
            for (const language of languages) {
                language.application_id = response.id;
                language.order = counter++;
                if (language.id) {
                    newLanguages.push(
                        await applicationLanguageApi.updateLanguage(language)
                    );
                    keepLanguages.push(language.id);
                } else {
                    newLanguages.push(
                        await applicationLanguageApi.createLanguage(language)
                    );
                }
            }
            for (const oldLang of response.languages) {
                if (keepLanguages.indexOf(oldLang.id as number) < 0) {
                    await applicationLanguageApi.deleteLanguage(
                        oldLang.id as number,
                        oldLang.application_id as number
                    );
                }
            }
            this.applicationLanguages = newLanguages;

            const keepEducations = [] as number[];
            const newEducations = [] as EducationInterface[];
            counter = 0;
            for (const education of educations) {
                education.application_id = response.id;
                education.order = counter++;
                if (education.id) {
                    newEducations.push(
                        await applicationEducationApi.updateEducation(education)
                    );
                    keepEducations.push(education.id);
                } else {
                    newEducations.push(
                        await applicationEducationApi.createEducation(education)
                    );
                }
            }
            for (const oldEducation of response.educations) {
                if (keepEducations.indexOf(oldEducation.id as number) < 0) {
                    await applicationEducationApi.deleteEducation(
                        oldEducation.id as number,
                        oldEducation.application_id as number
                    );
                }
            }
            this.applicationEducations = newEducations;
            await this.ensureApplication(application.id as number);
        },
        async updateVersionOnly(): Promise<void> {
            if (this.application) {
                const app = await applicationApi.findById(
                    this.application.id as number
                );
                if (app) {
                    this.application.version = app.version;
                }
            }
        },
    },
});
